import { render, staticRenderFns } from "./TeacherDocuments.vue?vue&type=template&id=3900e524&scoped=true&"
import script from "./TeacherDocuments.vue?vue&type=script&lang=js&"
export * from "./TeacherDocuments.vue?vue&type=script&lang=js&"
import style0 from "./TeacherDocuments.vue?vue&type=style&index=0&id=3900e524&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3900e524",
  null
  
)

export default component.exports
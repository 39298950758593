<template>
  <div>
    <!--Title Bar-->
    <title-bar v-if="documentSection === 1" :title-value="'Documents'"></title-bar>

    <!-- Title Bar For Section List-->
    <v-card flat>
      <v-toolbar dense flat color="secondary" class="mb-2" dark>
        <v-icon>fal fa-file-contract</v-icon>
        <v-toolbar-title class="pl-2">Documents</v-toolbar-title>
      </v-toolbar>
    </v-card>

    <!-- Filter -->
    <v-toolbar
      v-if="
        documentSection === 1 ||
          documentSection === 2 ||
          documentSection === 3 ||
          documentSection === 4
      "
      flat
      dense
    >
      <v-menu v-model="filterMenu" offset-y right :close-on-content-click="false">
        <template #activator="{ on }">
          <v-btn rounded color="primary" v-on="on"
            ><v-icon small left>fal fa-filter</v-icon><span v-if="showFiltered()">Filtered</span
            ><span v-else>Filter</span>
          </v-btn>
        </template>

        <v-card width="400px">
          <v-card-text>
            <v-container fluid>
              <!--Clear Filter Button -->
              <v-row dense>
                <v-col cols="12" sm="12" v-if="showFiltered()">
                  <v-btn block rounded color="primary" @click="clearFilter()">
                    <v-icon small left>fal fa-times</v-icon>Clear Filters
                  </v-btn>
                </v-col>
              </v-row>

              <v-row v-if="documentSection === 1" dense>
                <!--Provider License #-->
                <v-col cols="12" sm="12">
                  <v-text-field
                    v-model="d_licenseNumber"
                    label="Provider License #"
                    placeholder=" "
                    persistent-placeholder
                  />
                </v-col>
              </v-row>

              <v-row v-if="documentSection === 1" dense>
                <!--Provider License Name-->
                <v-col cols="12" sm="12">
                  <v-text-field
                    v-model="d_nameLicense"
                    label="Provider Name"
                    placeholder=" "
                    persistent-placeholder
                  />
                </v-col>
              </v-row>

              <v-row v-if="documentSection === 1" dense>
                <!--Teacher ID-->
                <v-col cols="12" sm="12">
                  <v-text-field
                    v-model="teacherID"
                    label="Teacher ID"
                    placeholder=" "
                    persistent-placeholder
                  />
                </v-col>
              </v-row>

              <v-row v-if="documentSection === 1" dense>
                <!--Household ID-->
                <v-col cols="12" sm="12">
                  <v-text-field
                    v-model="householdID"
                    label="Household ID"
                    placeholder=" "
                    persistent-placeholder
                  />
                </v-col>
              </v-row>

              <v-row v-if="documentSection === 1" dense>
                <!--Child ID-->
                <v-col cols="12" sm="12">
                  <v-text-field
                    v-model="clientID"
                    label="Child ID"
                    placeholder=" "
                    persistent-placeholder
                  />
                </v-col>
              </v-row>

              <v-row dense>
                <!-- Document Type -->
                <v-col cols="12" sm="12">
                  <v-autocomplete
                    :items="documentTypesStore.documentTypesValueList"
                    item-text="d_name"
                    item-value="d_name"
                    label="Type"
                    placeholder=" "
                    persistent-placeholder
                    v-model="d_type"
                    key="id"
                    clearable
                    clear-icon="fal fa-times-circle"
                  />
                </v-col>
              </v-row>

              <v-row dense>
                <!--Status-->
                <v-col cols="12" sm="12">
                  <v-autocomplete
                    :items="valueListsStore.activeStatus"
                    label="Status"
                    placeholder=" "
                    persistent-placeholder
                    item-text="value"
                    item-value="id"
                    v-model="f_status"
                    clearable
                    clear-icon="fal fa-times-circle"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <!--Submit Button-->
            <v-btn block rounded color="primary" @click="filter()">
              <v-icon small left>fal fa-check</v-icon>Submit
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-menu>
    </v-toolbar>

    <!--Pagination  -->
    <pagination
      :show="documentsStore.documents.data && documentsStore.documents.data.length > 0"
      :showTotal="true"
      :currentPage="documentsStore.documents.current_page"
      :lastPage="documentsStore.documents.last_page"
      :total="documentsStore.documents.total"
      v-on:paginate-event="filter($event)"
    />

    <div v-if="progressStore.progress !== true">
      <!--Document List-->
      <v-list
        v-if="documentsStore.documents.data && documentsStore.documents.data.length > 0"
        two-line
      >
        <template v-for="(item, index) in documentsStore.documents.data">
          <v-list-item
            :key="item.id"
            @click.stop="
              openEntry(
                item.id,

                item.fk_teacherID,

                item.fk_clientID,

                item.fk_householdID ? item.fk_householdID : '',

                item.f_privateRecord === 1 ? 1 : item.client ? item.client.f_privateRecord : 0
              )
            "
            class="my-1"
          >
            <v-list-item-content>
              <v-list-item-title class="light-blue--text text--darken-4"
                >{{ item.d_type }}
                <v-chip
                  v-if="
                    (item.client && item.client.f_privateRecord === 1) || item.f_privateRecord === 1
                  "
                  color="error"
                  ><v-icon small>fal fa-lock</v-icon
                  ><span class="pl-2">Private Record</span></v-chip
                ></v-list-item-title
              >
              <v-list-item-title class="light-blue--text text--darken-4">{{
                item.d_title
              }}</v-list-item-title>
              <v-list-item-title v-if="item.provider" class="light-blue--text text--darken-4">{{
                item.provider.d_nameLicense
              }}</v-list-item-title>
              <v-list-item-title v-if="item.client" class="light-blue--text text--darken-4">{{
                item.client.d_nameDisplay
              }}</v-list-item-title>
              <v-list-item-title
                v-if="item.client && item.care_plan"
                class="light-blue--text text--darken-4"
                >Care Plan: {{ $_formatDate(item.care_plan.d_dateStart) }} -
                {{ $_formatDate(item.care_plan.d_dateEnd) }}</v-list-item-title
              >
              <v-list-item-title
                v-if="item.client && item.care_plan_item"
                class="light-blue--text text--darken-4"
                >Item: {{ $_formatDate(item.care_plan_item.d_dateStart) }} -
                {{ $_formatDate(item.care_plan_item.d_dateEnd) }}</v-list-item-title
              >
              <v-list-item-title class="text-caption grey--text text--darken-1"
                >Created: {{ $_formatTimestamp(item.created_at) }} &#8226; Modified:
                {{ $_formatTimestamp(item.updated_at) }}</v-list-item-title
              >
            </v-list-item-content>
            <v-list-item-action>
              <v-list-item-action-text
                ><span
                  class="text-body-1"
                  :class="
                    item.f_status === 1 || item.f_status === true
                      ? 'font-weight-bold green--text text--lighten-1'
                      : 'error--text'
                  "
                  >{{ $_activeStatus(item.f_status) }}</span
                >
              </v-list-item-action-text>
              <v-list-item-action-text>
                <v-tooltip
                  v-if="
                    item.f_privateRecord === 0 ||
                      (item.f_privateRecord === 1 &&
                        usersStore.authUser.f_viewPrivateRecords === 1) ||
                      (item.client && item.client.f_privateRecord === 0) ||
                      (item.client &&
                        usersStore.authUser.f_viewPrivateRecords === 1 &&
                        item.client.f_privateRecord === 1) ||
                      item.provider ||
                      !item.client
                  "
                  left
                  color="primary"
                >
                  <template #activator="{ on }">
                    <v-btn icon v-on="on" @click.stop="openDocument(item.d_path)">
                      <v-icon color="primary">
                        fal fa-eye
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>View Document</span>
                </v-tooltip>
              </v-list-item-action-text>
            </v-list-item-action>
          </v-list-item>
          <v-divider></v-divider>
        </template>
      </v-list>

      <v-list v-else class="py-2">
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="error--text text-subtitle-1 text-xs-center"
              >No Documents Exist</v-list-item-title
            >
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { createHelpers } from "vuex-map-fields";
import { mixin } from "@/mixins/mixin.js";
import { uploadHeader } from "@/env";
import TitleBar from "@/components/TitleBar";
import Pagination from "@/components/Pagination";

const { mapFields } = createHelpers({
  getterType: "documentsStore/getFilterField",
  mutationType: "documentsStore/updateFilterField"
});

export default {
  name: "Documents",
  components: {
    TitleBar,
    Pagination
  },
  mixins: [mixin],
  computed: {
    ...mapState({
      documentsStore: state => state.documentsStore,
      documentTypesStore: state => state.documentTypesStore,
      progressStore: state => state.progressStore,
      valueListsStore: state => state.valueListsStore,
      usersStore: state => state.usersStore
    }),
    ...mapFields([
      "page",
      "section",

      "fk_providerID",
      "fk_teacherID",
      "fk_householdID",
      "fk_clientID",
      "d_type",
      "f_status",
      "householdID",
      "clientID",
      "teacherID",
      "f_viewPrivateRecords",
      "d_licenseNumber",
      "d_nameLicense"
    ])
  },
  props: {
    /* All List = 1, Provider Sublist = 2, Client Sublist = 3, Teacher Sublist = 4 */
    documentSection: {
      type: Number,
      required: false
    }
  },
  created() {
    this.initialize();
  },
  data() {
    return {
      filterMenu: false
    };
  },
  methods: {
    async initialize() {
      if (this.documentSection === 1) {
        this.$store.dispatch("progressStore/set", true);
      }

      await this.changeSectionCheck();

      /* ---------- Provider Section ---------- */
      if (this.documentSection === 2) {
        /* Grab provider detail using uuid passed as url parameter */
        // await this.$store.dispatch('providersStore/read', this.$route.params.providerUUID);
        const provider = this.$store.getters["providersStore/getProvider"];
        /* Hard code provider ID in fitler for provider section */
        this.fk_providerID = provider.id;
        this.fk_householdID = "";
        this.fk_teacherID = "";
      } else if (this.documentSection === 3) {
        /* ---------- Client Section ---------- */
        // await this.$store.dispatch('clientsStore/read', this.$route.params.clientUUID);
        const client = this.$store.getters["clientsStore/getClient"];
        /* HArd code client ID in filter for client section */
        this.fk_providerID = "";
        this.fk_householdID = client.fk_householdID;
        this.fk_teacherID = "";
      } else if (this.documentSection === 4) {
        /* Grab teacher detail using uuid passed as url parameter */
        const teacher = this.$store.getters["teachersStore/getTeacher"];
        /* Hard code provider ID in fitler for provider section */
        this.fk_teacherID = teacher.id;
        this.fk_providerID = "";
        this.fk_householdID = "";
      } else {
        /* ---------- All ---------- */
        /* Clear hard coded filters */
        this.fk_providerID = "";
        this.fk_householdID = "";
        this.fk_teacherID = "";
      }

      const user = this.$store.getters["usersStore/getAuthUser"];
      this.f_viewPrivateRecords = user.f_viewPrivateRecords;

      /* Pull documents list */
      const filters = this.$store.getters["documentsStore/getFilters"];
      await this.$store.dispatch("documentsStore/fetch", filters);

      this.$store.dispatch("progressStore/set", false);
    },

    async loadValueLists(householdID) {
      let vlData;
      /* Provider */
      if (this.documentSection === 2) {
        vlData = {
          f_type: 1
        };
      } else if (this.documentSection === 3) {
        /* Client */
        vlData = {
          f_type: 2
        };
      } else if (this.documentSection === 4) {
        /* Teacher */
        vlData = {
          f_type: 3
        };
      } else {
        /* All */
        vlData = {
          f_type: ""
        };
      }
      await this.$store.dispatch("documentTypesStore/valueList", vlData);
      const data = {
        fk_providerID: "",
        fk_teacherID: "",
        fk_householdID: householdID
      };
      await this.$store.dispatch("clientsStore/valueList", data);
    },

    async filter(page) {
      this.filterMenu = false;
      this.page = page;
      this.initialize();
    },

    clearFilterColumns() {
      this.d_type = "";
      this.clientID = "";
      this.householdID = "";
      this.teacherID = "";
      this.f_status = "";
      this.d_licenseNumber = "";
      this.d_nameLicense = "";
      this.page = 1;
    },

    async changeSectionCheck() {
      await this.loadValueLists(this.fk_householdID);
      if (this.documentSection !== this.section) {
        this.clearFilterColumns();
        this.$store.dispatch("documentsStore/clearDocuments");
      }
      /* Save section in store */
      this.section = this.documentSection;
    },

    async clearFilter() {
      this.filterMenu = false;
      this.clearFilterColumns();
      this.initialize();
    },

    showFiltered() {
      if (
        this.d_type ||
        this.f_status ||
        this.f_status === 0 ||
        (this.documentSection === 1 && this.clientID) ||
        (this.documentSection === 1 && this.householdID) ||
        (this.documentSection === 1 && this.teacherID) ||
        (this.documentSection === 1 && this.d_licenseNumber) ||
        (this.documentSection === 1 && this.d_nameLicense)
      ) {
        return true;
      }
      return false;
    },

    async openEntry(id, teacherID, clientID, householdID, privateRecord) {
      const user = this.$store.getters["usersStore/getAuthUser"];
      if (privateRecord === 0 || (privateRecord === 1 && user.f_viewPrivateRecords === 1)) {
        /* If Client Details */
        if (this.documentSection === 3 || clientID) {
          await this.$store.dispatch("carePlansStore/clearValueList");
          let userData;
          if (this.documentSection === 3) {
            const client = this.$store.getters["clientsStore/getClient"];
            userData = {
              fk_clientID: client.id
            };
          } else {
            userData = {
              fk_clientID: clientID
            };
          }
          await this.$store.dispatch("carePlansStore/valueList", userData);
          await this.loadValueLists(householdID);
        } else if (this.documentSection === 4 || teacherID) {
          /*Load Teacher Name Value List */
          await this.$store.dispatch("teachersStore/valueList");
        }
        await this.$store.dispatch("documentsStore/entry", id);
      } else {
        const data = {
          color: "error",
          text: "Access to this record is prohibited."
        };
        this.$store.commit("messagesStore/setMessage", data);
      }
    },

    async openDocument(path) {
      const formData = new FormData();
      formData.append("file", path);
      const response = await axios.post("/api/documents/presignedurl", formData, {
        headers: uploadHeader()
      });
      window.open(response.data, "_blank");
    }
  }
};
</script>

<style scoped>
.clickable {
  cursor: pointer;
}
button {
  outline: none;
}
</style>
